import React from "react"

export default class HeroWork extends React.Component {
    render() {
  return (
    <div className="hero servicepage" style={{ backgroundImage: `url(http://waterproof.lw-dev.com/wordpress/wp-content/uploads/2021/07/hero-background.jpg)` }}>
      <div className="hero-box">
        <h2 className="hero-title">{this.props.herotitle}</h2>
        <p className="hero-text">{this.props.herotext}</p>
      </div>
    </div>
           );
        }
    }
  
