import React from "react"
import { Link } from "gatsby"
import ModalImage from "react-modal-image";
import { graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import Layout from "../components/Layout"
import HeroWork from "../components/HeroWork"
const BlogPostTemplate = ({ data, context }) => (
  <Layout >
<HeroWork herotitle={data.wpWork.title} herotext={data.wpWork.works.workSliderText}></HeroWork>
<Container className="work-section">
<Row>
 <Col lg="6">
   <data.wpWork.works.workSectionTag className="about-title">{data.wpWork.works.workSectionTitle}</data.wpWork.works.workSectionTag>
   <div className="work-text" dangerouslySetInnerHTML={{__html: data.wpWork.works.workSectionText}}></div>
</Col>   
</Row> 
<Row className="lightboxicon">
{data.wpWork.works.gallery.map((gallery) => {
            return (
                <Col lg="6">
                       <ModalImage className=""
  small={gallery.obrazek.sourceUrl}
  large={gallery.obrazek.sourceUrl}
  alt={gallery.obrazek.altText}
/>
                </Col>
                );
            })}
</Row>
</Container>
  </Layout>
  
) 
export default BlogPostTemplate
export const query = graphql`
  query($id: String!) {
    wpWork(id: {eq: $id}) {
        uri
        title
        slug
      
      works {
        workSliderText
        workSectionTitle
        workSectionText
        titleTag
        gallery {
            obrazek {
              sourceUrl
              altText
            }
          }
        workSectionTag
      }
    }
}
    
`
